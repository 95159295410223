import Vue from "vue";

export default {
  getTenantsList: (options) => {
    var url = `${process.env.VUE_APP_WALLSIGN_LOGGER_BASE_URL}/api/Tenants/?pageNumber=${options.pageNumber}`;
    //build url with params
    if (options.pageSize) {
      url += `&pageSize=${options.pageSize}`;
    }
    if (options.search) {
      url += `&search=${options.search}`;
    }
    if (options.sortBy) {
      url += `&sortBy=${options.sortBy}`;
    }
    if (options.sortDescending) {
      url += `&sortDescending=${options.sortDescending}`;
    }
    console.log(url);
    return Vue.prototype.$axios.get(url, {
      headers: {
        "x-functions-key": process.env.VUE_APP_WALLSIGN_LOGGER_KEY,
      },
    });
  },
  getPlayersList: (id, options) => {
    var url = `${process.env.VUE_APP_WALLSIGN_LOGGER_BASE_URL}/api/Players/${id}?pageNumber=${options.pageNumber}`;
    if (options.tenantId) {
      url += `&tenantId=${options.tenantId}`;
    }
    if (options.deviceType !== null) {
      url += `&deviceType=${options.deviceType}`;
    }
    if (options.pageSize) {
      url += `&pageSize=${options.pageSize}`;
    }
    if (options.search) {
      url += `&search=${options.search}`;
    }
    if (options.sortBy) {
      url += `&sortBy=${options.sortBy}`;
    }
    if (options.sortDescending) {
      url += `&sortDescending=${options.sortDescending}`;
    }

    return Vue.prototype.$axios.get(url, {
      headers: {
        "x-functions-key": process.env.VUE_APP_WALLSIGN_LOGGER_KEY,
      },
    });
  },
  getInfo: () => {
    return Vue.prototype.$axios.get(
      `${process.env.VUE_APP_WALLSIGN_LOGGER_BASE_URL}/api/Info?json=true`,
      {
        headers: {
          "x-functions-key": process.env.VUE_APP_WALLSIGN_LOGGER_KEY,
        },
      }
    );
  },
};
