<template>
  <v-row>
    <v-col cols="4">
      <info :info="newsInfo" :loading="newsInfoLoading"></info>
    </v-col>
    <v-col cols="4">
      <info :info="newsApiInfo" :loading="newsApiInfoLoading"></info>
    </v-col>
    <v-col cols="4">
      <info
        :info="wallsignLoggerApiInfo"
        :loading="wallsignLoggerApiInfoLoading"
      ></info>
    </v-col>

    <v-col cols="4" v-for="platform in platforms" :key="platform.id">
      <info
        :info="socialInfo[platform.id]"
        :loading="socialInfoLoading[platform.id]"
      ></info>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

import SocialService from "@/services/SocialService";
import NewsService from "@/services/NewsService";
import LoggerService from "@/services/WallSignLoggerService";

export default {
  name: "Home",
  components: {
    Info: () => import("@/components/Info"),
  },
  data: () => ({
    socialInfoLoading: {},
    newsInfoLoading: true,
    newsApiInfoLoading: true,
    wallsignLoggerApiInfoLoading: true,
    socialInfo: {},
    newsInfo: {
      name: null,
      version: null,
      buildDate: null,
    },
    newsApiInfo: {
      name: null,
      version: null,
      buildDate: null,
    },
    wallsignLoggerApiInfo: {
      name: null,
      version: null,
      buildDate: null,
    },
  }),
  mounted() {
    //
    NewsService.getNewsApiInfo()
      .then((result) => {
        this.newsApiInfo.name = result.data.name;
        this.newsApiInfo.version = result.data.version;
        this.newsApiInfo.buildDate = result.data.buildDate;
      })
      .finally(() => {
        this.newsApiInfoLoading = false;
      });
    NewsService.getNewsInfo()
      .then((result) => {
        this.newsInfo.name = result.data.name;
        this.newsInfo.version = result.data.version;
        this.newsInfo.buildDate = result.data.buildDate;
      })
      .finally(() => {
        this.newsInfoLoading = false;
      });

    LoggerService.getInfo()
      .then((result) => {
        this.wallsignLoggerApiInfo.name = result.data.name;
        this.wallsignLoggerApiInfo.version = result.data.version;
        this.wallsignLoggerApiInfo.buildDate = result.data.buildDate;
      })
      .finally(() => {
        this.wallsignLoggerApiInfoLoading = false;
      });

    this.platforms.forEach((platform) => {
      this.$set(this.socialInfoLoading, platform.id, true);
    });

    this.platforms.forEach((platform) => {
      SocialService.getInfo(platform.id)
        .then((result) => {
          this.$set(this.socialInfo, platform.id, {
            name: `${platform.name} Social Aggregator`,
            version: result.data.version,
            buildDate: result.data.buildDate,
          });
        })
        .catch(() => {
          this.$set(this.socialInfo, platform.id, {
            name: `${platform.name} (Error)`,
            version: "0.0.0.0",
            buildDate: "An error occurred",
          });
        })
        .finally(() => {
          this.$set(this.socialInfoLoading, platform.id, false);
        });
    });
  },
  computed: {
    ...mapState({
      platforms: (state) => state.platforms,
    }),
  },
};
</script>
